import React, { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import { Trigger } from '../../../../../../../../entities/applicant_tracking/Trigger';
import { mapStagesToSelectOption } from '../../triggerSelectOptions';
import { PlanTemplate } from '../../../../../../../../entities/applicant_tracking/PlanTemplate';
import { MultiSelectAsyncFilter } from '../../../../../../../../components/MultiSelectAsyncFilter';

interface PropTypes {
  label: string;
  trigger: Trigger;
  planTemplate: PlanTemplate;
  selectedOptions: number[];
  setSelectedStageOptions: (stages: number[]) => void;
  onlyInterviewStages: boolean;
}

export function StagesDropdown(props: PropTypes) {
  const [alertMandatory, setAlertMandatory] = useState(null);

  useEffect(() => {
    if (props.selectedOptions?.length === 0) {
      if (alertMandatory == null) {
        setAlertMandatory(null);
      } else {
        setAlertMandatory(true);
      }
    } else {
      setAlertMandatory(false);
    }
  }, [props.selectedOptions]);

  useEffect(() => {
    if (props.planTemplate != null) {
      props.setSelectedStageOptions(
        props.planTemplate.jobStages
          .filter((i) => props.selectedOptions?.some((v) => v === i.jobStageId))
          .map((v) => v.jobStageId),
      );
    }
  }, [props.planTemplate]);

  return (
    <TriggerRowDropdown alertDanger={alertMandatory}>
      <MultiSelectAsyncFilter
        isMulti={true}
        cacheId={props.planTemplate?.id}
        danger={alertMandatory}
        loadOptions={(name, _page) =>
          mapStagesToSelectOption(
            name,
            props.planTemplate,
            props.selectedOptions,
            props.onlyInterviewStages,
          )
        }
        selected={props.planTemplate.jobStages
          .filter((i) => props.selectedOptions?.some((v) => v === i.jobStageId))
          .map((v) => {
            return {
              value: v.jobStageId.toString(),
              label: v.name,
              select: true,
            };
          })}
        label={props.label}
        placeholder='Select stage'
        onChange={(options: SelectOption[]) =>
          props.setSelectedStageOptions(options.map((v) => parseInt(v.value)))
        }
        mandatory
      />
      {alertMandatory && (
        <small className='text-danger-500'>This field cannot be empty.</small>
      )}
    </TriggerRowDropdown>
  );
}
