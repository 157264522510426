import React, { useState } from 'react';
import { BulkActionButton } from '../BulkActionButton';
import { PushToHrisModal } from '../../../../../../CandidatePage/CandidateActions/PushToHrisModal';
import { getHrisName } from '../../../../../../../utils/hris';
import { HrisEmployee } from '../../../../../../../entities/applicant_tracking/HrisEmployees';
import { HrisProvider } from '../../../../../../../enums/IntegrationProvider/HrisProvider';
import { AlertObject } from '../../../../../../../components/Alert';
import { checkIfAnySelectedCandidatesAreRedacted } from '../utils';
import { NewListCandidate } from '../../../../../../../entities/v1/applicant_tracking/NewListCandidate';

interface PropTypes {
  candidates: NewListCandidate[];
  selectedCandidates: NewListCandidate[];
  reloadPage: () => void;
  hrisEmployees: HrisEmployee[];
  hrisProvider?: HrisProvider;
  setAlert: (alert: AlertObject) => void;
}

export function PushToHrisButton(props: PropTypes) {
  if (!props.hrisProvider) {
    return null;
  }

  // only try to push the candidates that were not exported yet
  const applicationIdsToPush = props.selectedCandidates
    .map((c) => c.application?.id)
    .filter(Boolean)
    .filter((id) => !props.hrisEmployees.find((e) => e.applicationId === id));

  if (applicationIdsToPush.length == 0) {
    return null;
  }

  const [isPushToHrisModalOpen, setIsPushToHrisModalOpen] =
    useState<boolean>(false);

  const actionDisabled =
    props.selectedCandidates.length === 0 ||
    !props.selectedCandidates.every((c) => c.application?.status === 'hired') ||
    checkIfAnySelectedCandidatesAreRedacted(props.selectedCandidates);

  const hrisName = getHrisName(props.hrisProvider);
  const toolTipText = actionDisabled
    ? `Bulk export to ${hrisName} is only available for hired and not-redacted candidates`
    : `Export to ${hrisName}`;

  const handlePushToHrisModalClose = () => {
    setIsPushToHrisModalOpen(false);
    props.reloadPage();
  };

  return (
    <>
      <BulkActionButton
        iconName='bi-journal-plus'
        toolTipText={toolTipText}
        disabled={actionDisabled}
        onClick={() => setIsPushToHrisModalOpen(true)}
      />
      <PushToHrisModal
        applicationIds={applicationIdsToPush}
        allowedHrisProvider={props.hrisProvider}
        isOpen={isPushToHrisModalOpen}
        onClose={handlePushToHrisModalClose}
        setAlert={props.setAlert}
      />
    </>
  );
}
