import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { MoveCandidateProps } from '../../../../../../JobPage/components/MoveCandidateStageModal';
import { ApplicationService } from '../../../../../../../services/applicant_tracking/ApplicationService';
import { ListCandidateApplicationJob } from '../../../../../../../entities/applicant_tracking/ListCandidate';
import { getLabelForCount } from '../../../../../../../utils/grammar';
import { Select, SelectOption } from '../../../../../../../components/Select';
import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner';
import {
  NewListCandidate,
  NewListCandidateApplicationJobStage,
} from '../../../../../../../entities/v1/applicant_tracking/NewListCandidate';

interface ModalProps {
  job: ListCandidateApplicationJob;
  selectedCandidates: NewListCandidate[];
  onClose: (action?: MoveCandidateProps) => void;
}

function buildOptions(
  options: NewListCandidateApplicationJobStage[],
): SelectOption[] {
  return options
    .sort((a, b) => a.order - b.order)
    .map((option, index) => ({
      label: `${index + 1}. ${option.name}`,
      value: option.id.toString(),
      classNames: 'justify-content-start',
    }));
}

export function ModalBody(props: ModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const jobStages = props.job.jobStages;

  const options = buildOptions(jobStages);

  const moveCandidateStage = async () => {
    setIsLoading(true);
    const selectedJobStage = jobStages.find(
      (stage) => stage.id === selectedOption,
    );

    try {
      const { recordsProcessed } = await ApplicationService.bulkMoveStage({
        applicationIds: props.selectedCandidates.map((c) => c.application.id),
        jobStageId: selectedJobStage.id,
      });

      props.onClose({
        state: 'Success',
        jobStageName: selectedJobStage.name,
        recordsProcessed,
      });
    } catch (error) {
      props.onClose({
        state: 'Error',
        jobStageName: selectedJobStage.name,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <div className='mb-2 fs-5'>
        To which stage do you want to move{' '}
        {props.selectedCandidates.length > 1 ? 'these ' : 'this '}
        {getLabelForCount('candidate', props.selectedCandidates.length)}?
      </div>
      <Select
        options={options}
        placeholder='Select stage'
        selected={options.find(
          (o: SelectOption) => selectedOption?.toString() === o.value,
        )}
        onChange={(option: SelectOption) =>
          setSelectedOption(parseInt(option.value))
        }
      />
      <div className='d-flex mt-5 justify-content-end'>
        <Button className='me-2' color='danger' onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button
          color='primary'
          disabled={selectedOption == null}
          onClick={moveCandidateStage}
        >
          Move
        </Button>
      </div>
    </>
  );
}
