import React from 'react';
import { NewListCandidate } from '../../../../../../../../entities/v1/applicant_tracking/NewListCandidate';

interface PropTypes {
  candidate: NewListCandidate;
}

export function Jobs(props: PropTypes) {
  if (
    props.candidate.application == null ||
    props.candidate.application.job == null
  )
    return (
      <div className='d-flex justify-content-center'>
        <span>-</span>
      </div>
    );

  return (
    <div className='d-flex align-items-center fs-5'>
      <div className='text-truncate'>
        {props.candidate.application.job.name}
      </div>
    </div>
  );
}
