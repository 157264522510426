import React, { useEffect, useState } from 'react';
import { Header } from '../Header';
import { Col, Row } from 'reactstrap';
import { CheckboxButton } from '../../../../../components/CheckboxButton';
import { Select, SelectOption } from '../../../../../components/Select';
import styles from './styles.module.scss';
import { UseFormReturn } from 'react-hook-form';
import { DataPrivacySettingsForm } from '..';

const DATA_RETENTION_OPTIONS: SelectOption[] = [
  { label: '3 months', value: '3' },
  { label: '6 months', value: '6' },
  { label: '1 year', value: '12' },
  { label: '2 years', value: '24' },
];

const FORM_FIELD = 'retentionPeriodMonths';

interface PropTypes {
  form: UseFormReturn<DataPrivacySettingsForm, any>;
  dataRetentionMonths: number;
}

export function DataRetentionPolicy(props: PropTypes) {
  const storedValue = DATA_RETENTION_OPTIONS.find(
    (option) => option.value === props.dataRetentionMonths?.toString(),
  );
  const [selectedOption, setSelectedOption] = useState<SelectOption>(
    storedValue ?? DATA_RETENTION_OPTIONS[0],
  );
  const [checked, setChecked] = useState(storedValue !== undefined ?? false);

  useEffect(() => {
    if (checked) {
      props.form.setValue(FORM_FIELD, parseInt(selectedOption.value) ?? null, {
        shouldTouch: true,
      });
    } else {
      props.form.setValue(FORM_FIELD, null, { shouldTouch: true });
    }
  }, [checked, selectedOption]);

  return (
    <Row>
      <Col xs='6'>
        <Header
          title='Data Retention Policy'
          subtitle="Choose how long to retain candidate data after rejecting all their applications. The anonymisation date is set by adding this period to the application's creation date."
        />
        <div className='d-flex pt-2'>
          <CheckboxButton
            name='anonymization'
            checked={checked}
            labelClasses='me-4'
            label='Automatically anonymise candidate data after'
            onChange={() => setChecked(!checked)}
          />
          <Select
            options={DATA_RETENTION_OPTIONS}
            selected={selectedOption}
            disabled={!checked}
            className={styles.select}
            onChange={(option) => setSelectedOption(option)}
          />
        </div>
      </Col>
    </Row>
  );
}
