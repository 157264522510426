import React from 'react';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';
import { NewListCandidateApplication } from '../../../../../../../../entities/v1/applicant_tracking/NewListCandidate';

interface PropTypes {
  application?: NewListCandidateApplication;
}

export function PendingActions(props: PropTypes) {
  if (props.application == null)
    return (
      <div className='d-flex justify-content-center'>
        <span>-</span>
      </div>
    );

  return (
    <Chip className='py-1 px-2h fs-6 fw-semibold' color={ChipColor.DangerLight}>
      {props.application.pendingActions > 0
        ? `${props.application.pendingActions} pending`
        : 'No actions'}
    </Chip>
  );
}
