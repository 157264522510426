import React from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { EmptyState } from '../../../../components/EmptyState';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { JobRequisition } from '../../../../entities/v1/applicant_tracking/JobRequisition';
import { SetupJobPageRoutes } from '../../Jobs/SetupJobPageRoutes';

interface PropTypes {
  changeLiveWorkflow: boolean;
  jobRequisitionEnabled: boolean;
  jobRequisitionMandatory: boolean;
  jobRequisition: JobRequisition;
  hrisProvider?: HrisProvider;
  generateJobDescriptionEnabled: boolean;
}

export function ApplicantTrackingJobRequisitionsApp(props: PropTypes) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'applicant_tracking/job_requisitions'}>
          <Route
            path={':id/create_job/*'}
            element={
              <SetupJobPageRoutes
                changeLiveWorkflow={props.changeLiveWorkflow}
                hrisProvider={props.hrisProvider}
                jobRequisition={props.jobRequisition}
                jobRequisitionEnabled={props.jobRequisitionEnabled}
                jobRequisitionMandatory={props.jobRequisitionMandatory}
                updateJobRequisitionEnabled={false}
                generateJobDescriptionEnabled={
                  props.generateJobDescriptionEnabled
                }
              />
            }
          />
          <Route
            path='*'
            element={
              <EmptyState
                title={'Page not found!'}
                text={
                  'For more information contact our support team at support@screenloop.com'
                }
                cardless={true}
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
