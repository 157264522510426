import { NewListCandidate } from '../../../../../../entities/v1/applicant_tracking/NewListCandidate';

export function checkIfSelectedCandidatesHaveSameStatus(
  selectedCandidates: NewListCandidate[],
): boolean {
  if (selectedCandidates.length === 0) return false;

  const firstStatus = selectedCandidates[0].application.status;
  return selectedCandidates.every((c) => c.application.status === firstStatus);
}

export function checkIfAnySelectedCandidatesAreRedacted(
  selectedCandidates: NewListCandidate[],
): boolean {
  return selectedCandidates.some((c) => c.redacted);
}

export function checkIfSelectedCandidatesHaveSameJob(
  selectedCandidates: NewListCandidate[],
): boolean {
  if (selectedCandidates.length === 0) return false;

  const firstJobId = selectedCandidates[0].application.job.id;
  return selectedCandidates.every((c) => c.application.job.id === firstJobId);
}

export function checkIfSelectedCandidatesAreInTheSameInterviewStage(
  selectedCandidates: NewListCandidate[],
): boolean {
  if (selectedCandidates.length === 0) return false;

  const firstInterviewStageId =
    selectedCandidates[0].application.currentStage.interviewStageId;

  if (firstInterviewStageId == null) {
    return false;
  }

  return selectedCandidates.every(
    (c) =>
      c.application.currentStage.interviewStageId === firstInterviewStageId,
  );
}
