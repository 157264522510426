import { extractNumbers } from './extractors/extractNumbers';

export function toIntOrNull(value?: string): number {
  if (value == null || value?.trim() == '') {
    return null;
  }

  const parsedInt = parseInt(extractNumbers(value), 10);
  if (!isNaN(parsedInt)) {
    return parsedInt;
  } else {
    return null;
  }
}
