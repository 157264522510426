import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { TableFooter } from './TableFooter';
import { User } from '../../../../../../entities/User';
import { HrisEmployee } from '../../../../../../entities/applicant_tracking/HrisEmployees';
import { HrisProvider } from '../../../../../../enums/IntegrationProvider/HrisProvider';
import { AlertObject } from '../../../../../../components/Alert';
import { EmailAccountIntegration } from '../../../../../../entities/EmailAccountIntegration';
import { ApplicationListingFilters } from '../ListApplicationsFilters/utils';
import { SortingColumn } from '../../../../../../utils/sorting';
import { NewListCandidate } from '../../../../../../entities/v1/applicant_tracking/NewListCandidate';

interface PropTypes {
  candidates: NewListCandidate[];
  currentUser: User;
  emptyState: React.ReactNode;
  emailAccountIntegration: EmailAccountIntegration;
  filterStates: ApplicationListingFilters;
  hrisEmployees: HrisEmployee[];
  hrisProvider?: HrisProvider;
  selectedCandidates: NewListCandidate[];
  totalFilteredCandidates: number;
  onFilterStatesUpdate: (fs: ApplicationListingFilters) => void;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
  setSelectedCandidates: (candidates: NewListCandidate[]) => void;
}

const columns = [
  {
    key: 'candidate',
    label: 'Candidate',
    type: 'string',
    isSortable: true,
    className: 'col-2',
  },
  {
    key: 'jobs',
    label: 'Job Application',
    tooltipText: 'Candidate job application.',
    type: 'string',
    isSortable: false,
    className: 'col-2',
  },
  {
    key: 'updated_at',
    label: 'Updated At',
    tooltipText: 'Latest candidate application update.',
    type: 'string',
  },
  { key: 'channel', label: 'Channel', type: 'string', isSortable: false },
  { key: 'stages', label: 'Current Stage', type: 'string', isSortable: false },
  {
    key: 'actions',
    label: 'Actions',
    tooltipText: 'Your pending actions.',
    type: 'string',
    isSortable: false,
  },
  {
    key: 'dropdown_menu',
    label: '',
    type: 'string',
    isSortable: false,
  },
];

function filterSelectableCandidates(candidates: NewListCandidate[]) {
  return candidates
    .filter((candidate) => candidate.status !== 'draft')
    .filter((candidate) => candidate.application?.bulkActionsPermitted);
}

function addToSelectedList(
  selectedCandidates: NewListCandidate[],
  candidates: NewListCandidate[],
) {
  const combinedList = [
    ...selectedCandidates,
    ...filterSelectableCandidates(candidates),
  ];

  return combinedList.filter(
    (candidate, index, self) =>
      index === self.findIndex((c) => c.id === candidate.id),
  );
}

function removeFromSelectedList(
  selectedCandidates: NewListCandidate[],
  candidates: NewListCandidate[],
) {
  return selectedCandidates.filter(
    (c) => !candidates.find((candidate) => candidate.id === c.id),
  );
}

function isEntireListSelected(
  childList: NewListCandidate[],
  parentList: NewListCandidate[],
) {
  const selectableList = filterSelectableCandidates(childList);
  if (selectableList.length === 0) return false;

  return selectableList.every((c) =>
    parentList.map((selectedCandidate) => selectedCandidate.id).includes(c.id),
  );
}

function selectedCountText(selectedCandidates: NewListCandidate[]) {
  const selectedCount = selectedCandidates.length;
  return selectedCount === 1
    ? `${selectedCount} candidate is selected`
    : `${selectedCount} candidates are selected`;
}

export function ListApplicationsTable(props: PropTypes) {
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false);

  useEffect(() => {
    setIsHeaderCheckboxChecked(
      isEntireListSelected(props.candidates, props.selectedCandidates),
    );
  }, [props.candidates, props.selectedCandidates]);

  const selectedCandidates = (checked: boolean) => {
    return checked
      ? addToSelectedList(props.selectedCandidates, props.candidates)
      : removeFromSelectedList(props.selectedCandidates, props.candidates);
  };

  return (
    <>
      <Table className='mb-0 table-fixed'>
        <TableHeader
          columns={columns}
          checked={isHeaderCheckboxChecked}
          checkboxDisabled={
            filterSelectableCandidates(props.candidates).length === 0
          }
          onSelectedCandidatesUpdate={(checked: boolean) => {
            props.setSelectedCandidates(selectedCandidates(checked));
          }}
          sortColumn={props.filterStates.sortingColumn}
          onSortUpdate={(sortingColumn: SortingColumn) =>
            props.onFilterStatesUpdate({
              ...props.filterStates,
              sortingColumn: sortingColumn,
            })
          }
        />
        <TableBody
          candidates={props.candidates}
          currentUser={props.currentUser}
          emailAccountIntegration={props.emailAccountIntegration}
          hrisEmployees={props.hrisEmployees}
          hrisProvider={props.hrisProvider}
          selectedCandidates={props.selectedCandidates}
          setSelectedCandidates={props.setSelectedCandidates}
          reloadPage={props.reloadPage}
          setAlert={props.setAlert}
        />
      </Table>
      {props.totalFilteredCandidates > 0 ? (
        <TableFooter
          page={props.filterStates.page}
          pageSize={10}
          totalRows={props.totalFilteredCandidates}
          disabled={false}
          additionalInfo={
            props.selectedCandidates.length > 0 && (
              <span className='fw-semibold ms-4 text-dark-500'>
                {selectedCountText(props.selectedCandidates)}
              </span>
            )
          }
          onPageChanged={(newPage: number) =>
            props.onFilterStatesUpdate({
              ...props.filterStates,
              page: newPage,
            })
          }
        />
      ) : (
        props.emptyState
      )}
    </>
  );
}
