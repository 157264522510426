import React from 'react';
import { BulkActionButton } from '../BulkActionButton';
import { bulkApplicationReviewUrl } from '../../../../../../../utils/applicant_tracking/bulkApplicationReview';
import { NewListCandidate } from '../../../../../../../entities/v1/applicant_tracking/NewListCandidate';

interface PropTypes {
  selectedCandidates: NewListCandidate[];
}

export function BulkReviewButton(props: PropTypes) {
  const actionDisabled =
    props.selectedCandidates.length === 0 ||
    !props.selectedCandidates.every((c) => c.application?.status === 'active');
  const applicationIds = props.selectedCandidates
    .map((c) => c.application?.id)
    .filter(Boolean);

  return (
    <>
      <BulkActionButton
        iconName='bi-people'
        toolTipText={
          actionDisabled &&
          'Review candidates is only available for active candidates'
        }
        disabled={actionDisabled}
        buttonText='Review Candidates'
        onClick={() =>
          (window.location.href = bulkApplicationReviewUrl(applicationIds))
        }
      />
    </>
  );
}
